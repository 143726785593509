






































































































































































































import Vue from 'vue';
import QuizEventApi from '@/apis/QuizEventApi';
import {
  MonitorMapValue,
  PushedQuizGroupRecord, QuizEventModelv4, SentDataRecord, TimeMapValue,
} from '@/apis/models/QuizEventModel';
import checkedItems from '@/components/test_maker/CheckedItems.vue';
import ValidTimeSetting from '@/components/test_maker/ValidTimeSetting.vue';
import SendModeSetting from '@/components/test_maker/SendModeSetting.vue';
import GuidedTour from '@/views/GuidedTour.vue';
import OpenGuidedTourBtn from '@/components/OpenGuidedTourBtn.vue';
import GuidedTourItem from '@/components/GuidedTourItem.vue';
import Auth from '@/libs/auth';
import SelectQuizGroupModal from '@/components/test_maker/SelectQuizGroupModal.vue';
import SelectMemberGroupModal from '@/components/test_maker/SelectMemberGroupModal.vue';
import { WorkspaceObjectModel, WorkspaceObjectType } from '@/apis/models/WorkspaceObjectModel';
import { WorkspaceSoftLinkModel } from '@/apis/models/WorkspaceSoftLinkModel';
import BackButton from '@/components/BackButton.vue';
import TimeFunction from '@/libs/timeFunctions';
import QuizGroupApi from '@/apis/QuizGroupApi';
import { QuizGroupModel } from '@/apis/models/QuizGroupModel';
import QuizEventHandler from '@/handlers/QuizEventHandler';

export default Vue.extend({
  components: {
    GuidedTour,
    OpenGuidedTourBtn,
    GuidedTourItem,
    SendModeSetting,
    checkedItems,
    ValidTimeSetting,
    SelectQuizGroupModal,
    SelectMemberGroupModal,
    BackButton,
  },
  data() {
    return {
      quizEvent: null as QuizEventModelv4 | null, // 本[測驗]的資訊
      nowTimestamp: Math.floor(new Date(Math.floor(Date.now())).getTime() / 1000), // 10位數
      memberGroupsInfo: { // 目前props的時候傳入'member'，考慮要不要就乾脆拿掉了 [其實不能拿掉 還有地方在用 TODO:找找哪裡在用]
        name: 'member',
        checkedList: [] as WorkspaceObjectModel[], // 這是已選的[班級] 已被quizEvent.memberGroups代替
      },
      quizGroupsInfo: { // 目前props的時候傳入'quiz'，考慮要不要就乾脆拿掉了
        name: 'quiz',
        checkedList: [] as WorkspaceObjectModel[], // 這是已選的[題組] 已被quizEvent.quizGroups代替
      },
      dateTime: {
        scheduledTime: '',
        startValid: '',
        endValid: '',
        dateList: {
          dates: [] as string[],
        },
      },
      mapData: {
        unsendDates: [] as string[] || null,
        expiredDates: [] as string[],
        sentDataList: [] as SentDataRecord[],
        recordCodes: [] as string[],
        sentRecordCodes: [] as string[],
      },
      scheduleSetting: {
        allowRepeat: true,
        repeatPeriod: 'week', // 'days'
        repeatFrequency: 1,
        weeklyScheduledDay: ['1'],
      },
      validData: {
        startTimeHour: 0,
        startTimeMin: 0,
        endTimeDay: 0,
        endTimeHour: 0,
      },
      pushedQuizGroups: [] as QuizGroupModel[],
      timeMap: new Map<string, TimeMapValue>(),
      monitorMap: new Map<string, MonitorMapValue>(),
      saveSignalTimeoutId: 0,
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
    workspaceId(): string {
      return this.$store.state.currentWorkspace.uuid;
    },
    quizEventId(): string {
      return this.$store.state.currentQuizEvent.uuid;
    },
    showGuidedTour(): boolean {
      return this.$store.state.showGuidedTour;
    },
    showGuidedTourItem(): boolean {
      if (this.showGuidedTour && this.$route.path === '/manager/quizevent-maker') {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    Auth.loginCheck();
    this.$store.commit('updateLoading', true);
    try {
      await QuizEventApi.getQuizEvent(this.quizEventId).then(async (quizEvent) => {
        this.quizEvent = quizEvent;
        this.getQuizEventData();
        console.log('quizEvent:', quizEvent);

        this.getPushedRecord();

        this.getTimeMapData();
      });
    } finally {
      this.$store.commit('updateLoading', false);
    }
  },
  methods: {
    setShowGuidedTour(btn: boolean) {
      // this.showGuidedTour = btn;
      this.$store.commit('updateGuidedTour', btn);
    },
    getPushedRecord() {
      if (this.quizEvent && this.quizEvent.pushedQuizGroupList[0]) {
        this.quizEvent.pushedQuizGroupList.forEach(async (record: PushedQuizGroupRecord) => {
          const request = [] as Promise<QuizGroupModel>[];
          record.groupId.forEach((id) => {
            const promies = QuizGroupApi.retrieve(id);
            request.push(promies);
          });

          const quizGroupsArray = await Promise.all(request);
          let object = {} as SentDataRecord;
          object = {
            quizGroup: quizGroupsArray,
            scheduledTime: record.scheduledTime,
            recordCode: record.recordCode,
          };
          this.mapData.sentDataList.push(object as SentDataRecord);
          if (this.quizEvent && this.mapData.sentDataList.length === this.quizEvent.pushedQuizGroupList.length) {
            this.mapData.sentDataList.sort((a, b) => { // 排好順序
              const scheduledTimeA = a.scheduledTime;
              const scheduledTimeB = b.scheduledTime;
              if (scheduledTimeA < scheduledTimeB) {
                return -1;
              }
              if (scheduledTimeA > scheduledTimeB) {
                return 1;
              }
              return 0;
            });
            this.mapData.sentDataList.forEach((item) => {
              this.mapData.sentRecordCodes.push(item.recordCode);
            });
          }
          this.mapData.sentDataList = QuizEventHandler.getSentExamData(this.quizEvent, this.mapData);
        });
      }
    },
    getQuizEventData() {
      if (this.quizEvent) {
        this.scheduleSetting.allowRepeat = !this.quizEvent.onePush;
        this.scheduleSetting.repeatPeriod = this.quizEvent.period;
        this.scheduleSetting.weeklyScheduledDay = this.quizEvent.weekdays;

        if (this.quizEvent.quizGroups
            && this.quizEvent.memberGroups
            && this.quizEvent.quizGroups.length === 0
            && this.quizEvent.memberGroups.length === 0) {
          this.scheduleSetting.allowRepeat = true;
        }

        if (this.quizEvent.validData) {
          this.validData = this.quizEvent.validData;
        }

        if (this.quizEvent.frequency) {
          this.scheduleSetting.repeatFrequency = this.quizEvent.frequency;
        }

        if (this.quizEvent.scheduledTime) {
          this.dateTime.scheduledTime = this.quizEvent.scheduledTime;
        }

        if (this.quizEvent.startTime) {
          this.dateTime.startValid = this.quizEvent.startTime;
        }

        if (this.quizEvent.endTime) {
          this.dateTime.endValid = this.quizEvent.endTime;
        }
      }
    },
    getTimeMapData() {
      this.$store.commit('updateLoading', true);
      if (this.quizEvent && this.quizEvent.timeMap) {
        const result = QuizEventHandler.getMapData(this.quizEvent);
        this.mapData = result.mapData;
        this.scheduleSetting = result.scheduleSetting;
        this.quizEvent = QuizEventHandler.getExamData(this.quizEvent);
      }
      this.$store.commit('updateLoading', false);
    },
    getValidDateTime(scheduledTime: string) { // 工具
      const validTime = {
        startValid: '' as any,
        endValid: '' as any,
      };
      if (this.validData.startTimeHour !== 0 || this.validData.startTimeMin !== 0) { // 有設定時分的話
        let timestamp = TimeFunction.dateTimeStringToTimestamp(scheduledTime);
        timestamp += (3600000 * this.validData.startTimeHour + 60000 * this.validData.startTimeMin);
        const result = new Date(timestamp).toISOString();
        validTime.startValid = result;
      } else {
        validTime.startValid = null;
      }
      if (this.validData.endTimeDay !== 0 || this.validData.endTimeHour !== 0) { // 有設定時分的話
        let timestamp = TimeFunction.dateTimeStringToTimestamp(scheduledTime);
        timestamp += (86400000 * this.validData.endTimeDay + 3600000 * this.validData.endTimeHour);
        const result = new Date(timestamp).toISOString();
        validTime.endValid = result;
      } else {
        validTime.endValid = null;
      }
      return validTime;
    },
    setTimeMap() {
      if (this.quizEvent && this.quizEvent.quizGroups) {
        const quizList = this.quizEvent.quizGroups; // 題組List
        const dateList = this.dateTime.dateList.dates; // 時間日期List

        if (dateList.length > 0 && quizList.length === 0) {
          this.$notify({
            type: 'error',
            title: this.$t('錯誤').toString(),
            text: this.$t('有選時間，尚未選擇題組').toString(),
          });
          this.$store.commit('updateLoading', false);
          throw console.log('有選時間，尚未選擇題組');
        }
        if (quizList.length > 0 && dateList.length === 0) {
          console.log('有選題組，尚未選擇日期，現在後端過不了不能存');

        //   this.$notify({
        //     type: 'error',
        //     title: this.$t('錯誤').toString(),
        //     text: this.$t('有選題組，尚未選擇日期').toString(),
        //   });
        //   this.$store.commit('updateLoading', false);
        //   throw console.log('有選題組，尚未選擇日期');
        }

        // 創建js map
        this.timeMap = new Map();
        if (quizList.length === 0 && dateList.length === 0) {
          console.log('沒有dateList也沒有quizList', dateList.length, quizList.length);
          this.quizEvent.timeMap = null;
        } else {
          if (dateList.length > 1) { // 週期性推送的時候
            for (let index = 0; index < quizList.length; index += 1) {
              const startTime = this.getValidDateTime(dateList[index]).startValid;
              const endTime = this.getValidDateTime(dateList[index]).endValid;

              this.timeMap.set(quizList[index].uuid, {
                startTime,
                endTime,
                scheduledTime: dateList[index],
                duration: 0,
                recordCode: null,
              });
            }
          // } else if (dateList.length === 0) {
          //   const startTime = null;
          //   const endTime = null;
          //   this.timeMap.set(quizList[0].uuid, {
          //     startTime,
          //     endTime,
          //     scheduledTime: null,
          //     duration: 0,
          //     recordCode: null,
          //   });
          } else { // onePush的時候 dateList.length === 1
            const startTime = this.getValidDateTime(dateList[0]).startValid;
            const endTime = this.getValidDateTime(dateList[0]).endValid;
            this.timeMap.set(quizList[0].uuid, {
              startTime,
              endTime,
              scheduledTime: dateList[0],
              duration: 0,
              recordCode: null,
            });
          }

          // 轉成後端所需map格式並存進quizEvent
          const obj = Object.create(null);
          const iterator = this.timeMap.keys();
          for (let i = 0; i < this.timeMap.size; i += 1) {
            const key = iterator.next().value;
            obj[key] = this.timeMap.get(key);
          }
          this.quizEvent.timeMap = JSON.parse(JSON.stringify(obj));
        }
        console.log('timeMap:', this.quizEvent.timeMap);
      }
    },
    setMonitorMap() {
      if (this.quizEvent && this.quizEvent.quizGroups) {
        this.monitorMap = new Map();

        this.quizEvent.quizGroups.forEach((item) => {
          if (item.isExamMode === true) {
            this.monitorMap.set(item.uuid, {
              noScreenshot: true,
              noSwitchApp: true,
              noPhoneCall: true,
            });
          } else {
            this.monitorMap.set(item.uuid, {
              noScreenshot: false,
              noSwitchApp: false,
              noPhoneCall: false,
            });
          }
        });

        // 轉成後端所需map格式並存進quizEvent
        const obj = Object.create(null);
        const iterator = this.monitorMap.keys();
        for (let i = 0; i < this.monitorMap.size; i += 1) {
          const key = iterator.next().value;
          obj[key] = this.monitorMap.get(key);
        }
        this.quizEvent.monitorMap = JSON.parse(JSON.stringify(obj));
        console.log('monitorMap:', this.quizEvent.monitorMap);
      }
    },
    updateEvent() {
      if (!this.quizEvent) {
        throw new Error('quiz event not ready');
      }
      this.$store.commit('updateLoading', true);
      if (this.quizEvent.quizGroups && this.quizEvent.memberGroups) {
        this.setTimeMap();
      } else {
        this.$store.commit('updateLoading', false);
        throw console.log('TimeMap is not ready');
      }
      if (this.quizEvent.quizGroups && this.quizEvent.memberGroups) {
        this.setMonitorMap();
      } else {
        this.$store.commit('updateLoading', false);
        throw console.log('MonitorMap is not ready');
      }

      // 以下是需要上傳到後端儲存的內容
      this.quizEvent.validData = this.validData;
      if (this.quizEvent.quizGroups.length === 1 && this.dateTime.scheduledTime) {
        this.quizEvent.onePush = true;
      } else if (this.quizEvent.quizGroups.length > 0 && !this.dateTime.scheduledTime) {
        this.quizEvent.onePush = true;
        console.log('有題目但無發送時間，就將onePush開啟');
      } else {
        this.quizEvent.onePush = !this.scheduleSetting.allowRepeat;
      }
      this.quizEvent.period = this.scheduleSetting.repeatPeriod;
      this.quizEvent.frequency = this.scheduleSetting.repeatFrequency;
      this.quizEvent.weekdays = this.scheduleSetting.weeklyScheduledDay;
      // ---------------------------------------

      if (this.quizEvent.memberGroups.length === 0) {
        this.$notify({
          type: 'error',
          title: this.$t('錯誤').toString(),
          text: this.$t('未選擇班級').toString(),
        });
        this.$store.commit('updateLoading', false);
        throw console.log('未選擇班級');
      }
      if (this.quizEvent.onePush === false && this.quizEvent.period === 'week' && this.quizEvent.weekdays.length === 0) {
        this.$notify({
          type: 'error',
          title: this.$t('錯誤').toString(),
          text: this.$t('週間日期不得不選').toString(),
        });
        this.$store.commit('updateLoading', false);
        throw console.log('this.quizEvent.weekdays不得為0');
      }
      if (TimeFunction.dateTimeStringToTimestamp(this.dateTime.scheduledTime) / 1000 < this.nowTimestamp) {
        this.$notify({
          type: 'error',
          title: this.$t('錯誤').toString(),
          text: this.$t('發送時間小於現在').toString(),
        });
        this.$store.commit('updateLoading', false);
        throw console.log('發送時間小於現在');
      }

      console.log('請檢查:', this.quizEvent);

      QuizEventApi.update(this.quizEventId, this.quizEvent)
        .then(() => {
          console.log('儲存成功');
          this.$notify({
            type: 'success',
            title: this.$t('成功').toString(),
            text: this.$t('儲存成功').toString(),
          });
          window.location.reload(); // 重整頁面
          this.$store.commit('updateLoading', false);
        })
        .catch(
          (error) => {
            console.error('update儲存出錯囉', error);
          },
        )
        .finally(() => {
          this.$store.commit('updateLoading', false);
        });
    },
    openSelectMemberGroupModal() {
      this.$modal.show('select-member-group-modal', {
        defaultSelection: this.quizEvent?.memberGroups,
      });
    },
    updateSelectedMemberGroup(selection: WorkspaceObjectModel[]) {
      if (!this.quizEvent) {
        throw new Error('quiz event not ready');
      }

      this.quizEvent.memberGroups = selection;
    },
    openSelectQuizGroupModal() {
      this.$modal.show('select-quiz-group-modal', {
        defaultSelection: this.quizEvent?.quizGroups,
      });
    },
    updateSelectedQuizGroup(selection: WorkspaceObjectModel[]) {
      if (!this.quizEvent) {
        throw new Error('quiz event not ready');
      }

      if (selection.length > 1) {
        // TODO: quiz group only accept single selection, handle many selection
        // 已經可以接受複選了
      }

      if (selection.length === 1) {
        if (selection[0].metadata.type === WorkspaceObjectType.SoftLink
          && (selection[0] as WorkspaceSoftLinkModel).sourceObject.metadata.type !== WorkspaceObjectType.QuizGroup) {
          // TODO: handle wrong selection type
          console.log('selection等於1');
        } else if (selection[0].metadata.type !== WorkspaceObjectType.QuizGroup) {
          // TODO: handle wrong selection type
          console.log('selection等於1，是SoftLink');
        }
      }

      this.quizEvent.quizGroups = selection;
      // this.quizGroupsInfo.checkedList = selection;
    },
  },
});
